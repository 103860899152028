<template>
  <div class="checkCandidate container-list" @keydown.enter.prevent="">
    <div class="page-container">
      <div class="topHeader">
        <v-select
          v-if="false"
          class="user-select-search"
          label="email"
          :filterable="false"
          :options="searchOptions"
          placeholder="Cerca per nome, cognome o email..."
          @search="fetchOptions"
          @option:selected="email = $event.email; getCandidate()"
        >
          <template #no-options="{ search, loading }">
            <span v-if="loading">Attendi, ricerca in corso...</span>
           
            <span v-else-if="search">Nessun risultato trovato</span>
            <span v-else>Inizia a digitare...</span>
          </template>
          <template #option="{ email, first_name, last_name }">
            <p>
              {{ first_name }} {{ last_name }}
            </p>
            <p>{{ email }} </p>
          </template>
        </v-select>
        
        <searchBar
          style="margin-right: 15px"
          :search="findCandidate"
          @update:search="findCandidate = $event"
          @keydown.enter.prevent="searchCandidate"
        />
        <material-button
          :class="{ 'disabled-search': findCandidate === '' }"
          text="Cerca"
          type="info"
          :disabled="findCandidate === ''"
          @click="searchCandidate"
        />
      </div>
      <!-- Ricerca multipla -->
      <div v-if="intermediateSearch.length>0">
        <div class="table">
          <table cellspacing="0">
            <tr>
              Affina la ricerca cliccando sul risultato
            </tr>
           
            <tbody class="table-body">
              <slot>
                <tr v-for="(s, index) in intermediateSearch" :key="s+index" @click="getCandidate(s.email)">
                  <td> {{ s.uid }} </td>
                  <td> {{ s.first_name }} {{ s.last_name }}</td>
                  <td> {{ s.email }} </td>
                </tr>
              </slot>
            </tbody>
          </table>
        </div>
      </div>
      <!--    Form modifica dati del candidato -->
      <div
        v-if="candidateRegistry.name"
        class="table"
        style="margin-top: 35px; overflow: visible !important"
      >
        <table class="panel-table" cellspacing="0" style="overflow: visible">
          <thead
            class="candidateThead"
            :class="{ '--isOpen': openRegistry }"
            @click="
              openCampaigns = false;
              openRegistry = !openRegistry;
            "
            @keydown.enter.prevent=""
          >
            <th>
              {{ candidateRegistry.name }} {{ candidateRegistry.surname }}
            </th>
            <th class="th-action">
              <material-button
                v-if="false"
                text="Elimina account"
                type="danger"
                style="margin-right: 15px"
                class="small-button"
                @click="showDeleteAccount()"
              />
              <material-button
                v-if="permissions.canUse('CANDIDATE_MODIFY')"
                text="Elimina candidato"
                type="danger"
                class="small-button"
                @click="showDeleteCandidate()"
              />
            </th>
            <th class="th-chevron">
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="white"
                  d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                />
              </svg>
            </th>
          </thead>
          <tbody class="table-body">
            <slot>
              <tr v-if="openRegistry" class="collapsible-content">
                <td
                  colspan="7"
                  class="content-inner"
                  style="padding: 0 !important"
                >
                  <div style="padding: 0 15px">
                    <form @keydown.enter.prevent>
                      <div class="group group-50">
                        <material-input
                          type="text"
                          label="Nome candidato"
                          autocomplete="off"
                          :value="modifyCandidateRegistry.name"
                          @input="(e) => (modifyCandidateRegistry.name = e)"
                        />
                      </div>

                      <div class="group group-50">
                        <material-input
                          type="text"
                          label="Cognome candidato"
                          autocomplete="off"
                          :value="modifyCandidateRegistry.surname"
                          @input="(e) => (modifyCandidateRegistry.surname = e)"
                        />
                      </div>
                      <div v-show="false" class="group group-50">
                        <material-input
                          type="text"
                          label="Email"
                          autocomplete="off"
                          :value="modifyCandidateRegistry.email"
                          @input="(e) => (modifyCandidateRegistry.email = e)"
                        />
                      </div>

                      <div class="group group-50">
                        <material-date-picker
                          :date="modifyCandidateRegistry.birthDate"
                          label="Data di nascita"
                          :disabled="!modifyCandidateRegistry.fiscal_code"
                          @update:date="modifyCandidateRegistry.birthDate = $event"
                        />
                      </div>
                      <div class="group group-30">
                        <material-input
                          style="width: 100%"
                          type="text"
                          label="Città di nascita"
                          :disabled="!modifyCandidateRegistry.fiscal_code"
                          autocomplete="off"
                          :value="modifyCandidateRegistry.birthPlace"
                          @input="(e) => (modifyCandidateRegistry.birthPlace = e)"
                        />
                      </div>
                      <div class="group group-20 group-dropdown">
                        <div for="province" class="label">
                          Provincia di nascita
                        </div>
                        <Province v-model="modifyCandidateRegistry.birthProvince" :estero="true" :disabled="!modifyCandidateRegistry.fiscal_code" :valore="modifyCandidateRegistry?.birthProvince?.toString().toUpperCase()" />
                      </div>

                      <div class="group group-20">
                        <material-input
                          style="width: 100%"
                          type="text"
                          label="Città"
                          :disabled="!modifyCandidateRegistry.fiscal_code"
                          autocomplete="off"
                          :value="modifyCandidateRegistry.city"
                          @input="(e) => (modifyCandidateRegistry.city = e)"
                        />
                      </div>
                      <div class="group group-30">
                        <material-input
                          style="width: 100%"
                          type="text"
                          label="Indirizzo"
                          :disabled="!modifyCandidateRegistry.fiscal_code"
                          autocomplete="off"
                          :value="modifyCandidateRegistry.address"
                          @input="(e) => (modifyCandidateRegistry.address = e)"
                        />
                      </div>
                      <div class="group group-20">
                        <material-input
                          style="width: 100%"
                          type="text"
                          label="CAP"
                          :disabled="!modifyCandidateRegistry.fiscal_code"
                          autocomplete="off"
                          :value="modifyCandidateRegistry.cap"
                          @input="(e) => (modifyCandidateRegistry.cap = e)"
                        />
                      </div>
                      <div class="group group-30 group-dropdown">
                        <div for="province" class="label">
                          Provincia di residenza
                        </div>
                        <Province v-model="modifyCandidateRegistry.province" :estero="true" :disabled="!modifyCandidateRegistry.fiscal_code" :valore="modifyCandidateRegistry.province" />
                      </div>

                      <div class="group group-50 cf-input">
                        <material-input
                          style="width: 100%"
                          :disabled="cfIsEmpty"
                          type="text"
                          label="Codice fiscale"
                          autocomplete="off"
                          :value="modifyCandidateRegistry.fiscal_code"
                          @input="getfiscal_codeValidation($event)"
                        />
                        <Tooltip
                          :text="cfIsEmpty ? 'Il candidato non ha inserito un codice fiscale, non è quindi possibile modificare il dato fiscale': checkCodiceFiscale(modifyCandidateRegistry) ? 'Il codice fiscale è valido':'Il codice fiscale non è valido'"
                          :disabled="false"
                        >
                          <svg
                            v-if="cfIsEmpty"
                            style="width: 24px; height: 24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                            />
                          </svg>
                          <svg v-else-if="checkCodiceFiscale(modifyCandidateRegistry)" style="width:20px;height:20px" viewBox="0 0 24 24">
                            <path fill="#bcde42" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
                          </svg>
                          <svg v-else style="width:20px;height:20px" viewBox="0 0 24 24">
                            <path fill="#dc3545" d="M19,19H5V5H19M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M11,15H13V17H11V15M11,7H13V13H11V7" />
                          </svg>
                        </Tooltip>
                       
                        <p class="error-cf" :class="{ visible: !cfIsValid }">
                          Codice fiscale incompleto, errato o non valido.
                        </p>
                      </div>
                      <div class="group group-50">
                        <material-input
                          style="width: 100%"
                          :disabled="true"
                          :chips="true"
                          type="text"
                          label="UID Candidato"
                          autocomplete="off"
                          :value="modifyCandidateRegistry.uid"
                        />
                      </div>

                      <div style="width: 100%; text-align: right">
                        <material-button
                          v-if="permissions.canUse('CANDIDATE_MODIFY')"
                          :disabled="
                            modifyCandidateRegistry.name === '' ||
                              modifyCandidateRegistry.surname === ''
                          "

                          text="salva l'anagrafica del candidato"
                          type="info"
                          @click.prevent.stop="saveCandidate"
                        />
                      </div>
                    </form>
                  </div>
                </td>
              </tr>
            </slot>
          </tbody>
        </table>
      </div>
      <!-- Lista delle campagne -->
      <div v-if="modifyCandidateRegistry.uid && candidate.length" style="margin-top: 35px; overflow: hidden">
        <table
          class="table panel-table"
          cellspacing="0"
          style="overflow: hidden"
        >
          <thead
            class="candidateThead"
            :class="{ '--isOpen': openCampaigns }"
            @keydown.enter.prevent=""
            @click.prevent="
              openRegistry = false;
              openCampaigns = !openCampaigns;
            "
          >
            <th>
              {{ getCampaignList.length }}
              {{ getCampaignList.length === 1 ? "campagna " : "campagne " }}in
              cui è presente {{ candidateRegistry.name }}
              {{ candidateRegistry.surname }}
            </th>
            <th class="th-action">
              <searchBar
                v-show="openCampaigns"
                class="bg-dark"
                :search="searchCampaign"
                @focus="manageSearchCampaign"
                @input="manageSearchCampaign"
                @click.stop="manageSearchCampaign"
                @update:search="searchCampaign = $event"
              />
            </th>
            <th class="th-chevron">
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="white"
                  d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                />
              </svg>
            </th>
          </thead>
          <tbody
            v-if="openCampaigns"
            class="table-body"
            @keydown.enter.prevent=""
          >
            <tr>
              <td
                colspan="7"
                class="content-inner"
                style="padding: 0 !important"
              >
                <ul
                  v-show="candidate.length > 0"
                  class="lista-candidature"
                  style="overflow-y: auto"
                >
                  <li v-for="item in getCampaignList" :key="item">
                    <font-awesome-icon
                      v-if="permissions.canUse('CANDIDATE_MODIFY')"
                      icon="ellipsis-vertical"
                      class="campaign-options-button fa-lg"
                      @click="toggleCampaignOptions('' + item.cid + item.uid)"
                    />
                    <div
                      v-if="campaignOptionsVisible == '' + item.cid + item.uid"
                      class="campaign-options"
                    >
                      <span
                        v-if="item.status_id != 0"
                        @click="reopen(item.cid, item.uid)"
                      >Riapri candidatura</span>
                      <span
                        v-show="item.deleted"
                        @click="unhide(item.cid, item.uid)"
                      >Ripristina</span>
                      <span
                        @click="cancelApplication(item.cid, item.uid)"
                      >Elimina candidatura</span>
                    </div>
                    <div class="info-singola">
                      <div class="grid">
                        <label>Azienda</label>
                        <span>{{ item.company }}</span>
                      </div>
                    </div>
                    <div class="info-singola">
                      <div class="grid">
                        <label>Cid</label>
                        <span>{{ item.cid }}</span>
                      </div>
                    </div>
                    <div v-if="false" class="info-singola">
                      <div class="grid">
                        <label>Uid</label>
                        <span>{{ item.uid }}</span>
                      </div>
                    </div>
                    <div class="info-singola">
                      <div class="grid">
                        <label>Campagna</label>
                        <span>
                          <a
                            :href="
                              getApiUrl() + '/job-offers/id/' + item.cid + '/'
                            "
                            target="_blank"
                          >{{ item.campaign }}</a>
                        </span>
                      </div>
                    </div>

                    <div class="info-singola">
                      <div class="grid">
                        <label>Status campagna</label>
                        <span
                          class="badge-status"
                          :class="item.campaign_status.replace(' ', '-')"
                        >{{ item.campaign_status }}</span>
                      </div>
                    </div>
                    <div class="info-singola">
                      <div class="grid">
                        <label>Candidatura</label>
                        <span>{{ item.application_ts }}</span>
                      </div>
                    </div>
                    <div class="info-singola">
                      <div class="grid">
                        <label>Status candidatura</label>
                        <span>{{ item.status }}</span>
                      </div>
                    </div>
                    <div class="info-singola">
                      <div class="grid">
                        <label>Device</label>
                        <span>{{ item.device }}</span>
                      </div>
                    </div>
                    <div class="info-singola">
                      <div class="grid">
                        <label>Telefono</label>
                        <span>{{ item.phone }}</span>
                      </div>
                    </div>
                    <div class="info-singola">
                      <div class="grid">
                        <label>Video</label>
                        <span>{{ item.videos }}</span>
                      </div>
                    </div>
                    <div class="info-singola">
                      <div class="grid">
                        <label>CV</label>
                        <span class="flex-item-align">
                          <a
                            :href="getCandidateCVLink(item.cid, item.uid)"
                            target="_blank"
                          >
                            <svg
                              style="width: 24px; height: 24px"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M17,18C17.56,18 18,18.44 18,19C18,19.56 17.56,20 17,20C16.44,20 16,19.56 16,19C16,18.44 16.44,18 17,18M17,15C14.27,15 11.94,16.66 11,19C11.94,21.34 14.27,23 17,23C19.73,23 22.06,21.34 23,19C22.06,16.66 19.73,15 17,15M17,21.5A2.5,2.5 0 0,1 14.5,19A2.5,2.5 0 0,1 17,16.5A2.5,2.5 0 0,1 19.5,19A2.5,2.5 0 0,1 17,21.5M9.14,19.75L8.85,19L9.14,18.26C10.43,15.06 13.5,13 17,13C18.05,13 19.06,13.21 20,13.56V8L14,2H6C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H10.5C9.95,21.34 9.5,20.58 9.14,19.75M13,3.5L18.5,9H13V3.5Z"
                              />
                            </svg>
                          </a>
                          <span v-if="true">{{
                            getCandidateCVLink(item.cid, item.uid)
                          }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="info-singola">
                      <div class="grid">
                        <label>Type form</label>
                        <span v-if="item.typeform_link" class="flex-item-align">
                          <svg
                            style="width: 24px; height: 24px"
                            viewBox="0 0 24 24"
                            @click="copy(item.typeform_link)"
                          >
                            <path
                              fill="currentColor"
                              d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                            />
                          </svg>
                          <Tooltip
                            :text="item.typeform_link"
                            :disabled="item.typeform_link.length < 51"
                          >
                            {{ getTooltip(item.typeform_link) }}
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="candidate.length === 0 && candidateRegistry.uid"
        class="alert-candidate-campaign"
      >
        <span class="badge-alert">
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z"
            />
          </svg>
        </span>
        <span>
          {{ candidateRegistry.name }} {{ candidateRegistry.surname }} non si è
          mai candidato ad una campagna
        </span>
      </div>

      <div v-show="deleteCandidateForm" class="dialog">
        <div class="dialog-content">
          <div class="dialog-header">
            <div class="title">
              Sei sicuro di voler eliminare il candidato
              {{ candidateRegistry.name }} {{ candidateRegistry.surname }}?
            </div>
            <div class="close" @click="closeDeleteCandidate()">
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                />
              </svg>
            </div>
          </div>
          <div class="dialog-text" style="margin-top: 0">
            <div class="modal-badge">
              <svg
                class="cancel-user"
                style="width: 24px; height: 24px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z"
                />
              </svg>
              <div class="badge">
                <svg
                  style="padding-top: 11px; transform: scale(1.5)"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z"
                  />
                </svg>
              </div>
            </div>
            <div class="text-modal-cancel">
              Scrivi <span>ELIMINA</span> per confermare l'eliminazione del
              candidato.
            </div>
            <p>Attenzione, l'operazione è irreversibile.</p>

            <div>
              <input
                id="elimina"
                v-model="elimina"
                type="text"
                pattern="ELIMINA"
              >
              <material-button
                :class="{ 'disabled-search': elimina === '' }"
                text="Elimina il candidato"
                type="error"
                :disabled="elimina && elimina.toLowerCase() != 'elimina'"
                @click="deleteCandidate(candidateRegistry.uid)"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-show="deleteAccountForm && false" class="dialog">
        <div class="dialog-content">
          <div class="dialog-header">
            <div class="title">
              Sei sicuro di voler eliminare {{ candidateRegistry.name }}
              {{ candidateRegistry.surname }}?
            </div>
            <div class="close" @click="closeDeleteAccount()">
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                />
              </svg>
            </div>
          </div>
          <div class="dialog-text" style="margin-top: 0">
            <div class="modal-badge">
              <svg
                class="cancel-user"
                style="width: 24px; height: 24px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z"
                />
              </svg>
              <div class="badge">
                <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                  />
                </svg>
              </div>
            </div>
            <div class="text-modal-cancel">
              Scrivi <span>ELIMINA</span> per confermare l'eliminazione
              dell'account.
            </div>
            <p>Attenzione, l'operazione è irreversibile.</p>

            <div>
              <input
                id="elimina"
                v-model="elimina"
                type="text"
                pattern="ELIMINA"
              >
              <material-button
                :class="{ 'disabled-search': elimina === '' }"
                text="Elimina l'account"
                type="error"
                :disabled="elimina && elimina.toLowerCase() != 'elimina'"
                @click="deleteAccount(candidate[0].uid)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import dayjs from 'dayjs'
require('dayjs/locale/it')
import Tooltip from '../components/Tooltip'
import searchBar from '../components/searchBarList.vue'
import Province from '../components/Province.vue'
import moment from 'moment'
import 'moment/locale/it'
import { api, sbapibackoffice } from '@/api'
import { spinnerStore } from '@/stores/spinner'
import { navbarStore } from '@/stores/navbar'
import { reactive, toRefs } from '@vue/reactivity'
import { computed, inject, onMounted } from '@vue/runtime-core'
import codicefiscale from 'codice-fiscale-js'
import { permissionsStore } from '@/stores/permissions'

export default {
  components: {
    searchBar,
    Tooltip,
    Province
  },
  setup() {
    const permissions = permissionsStore()
    const state = reactive({
      intermediateSearch:[],
      searchOptions: [],
      cfIsEmpty: false,
      openRegistry: true,
      openCampaigns: false,
      candidateRegistry: {},
      modifyCandidateRegistry: {},
      searchCampaign: '',
      findCandidate: '',
      candidate: [],
      returned: false,
      deleteAccountForm: false,
      deleteCandidateForm: false,
      elimina: '',
      campaignOptionsVisible: false,
      spinner: spinnerStore(),
      navbar: navbarStore(),
      toast: inject('$toast'),
      cfIsValid: true
    })

    const showElimina = computed(() => {
      return state.elimina == 'ELIMINA'
    })

    onMounted(() => {
      state.navbar.title = 'Gestione Candidati'
      //this.$parent.hideOuterFrame = false
      moment.locale('it')
    })

    const toggleCampaignOptions = (idCampaignOptions) => {
      if (state.campaignOptionsVisible == idCampaignOptions)
        state.campaignOptionsVisible = false
      else state.campaignOptionsVisible = idCampaignOptions
    }
    const getApiUrl = () => {
      return process.env.VUE_APP_API
    }

    const showDeleteAccount = () => {
      state.deleteAccountForm = true
    }

    const showDeleteCandidate = () => {
      state.deleteCandidateForm = true
    }

    const closeDeleteAccount = () => {
      state.elimina = ''
      state.deleteAccountForm = false
    }

    const closeDeleteCandidate = () => {
      state.elimina = ''
      state.deleteCandidateForm = false
    }

    const deleteCandidate = (uid) => {
      api
        .post('/auth/delete-account', { uid })
        .then(() => {
          state.email = ''
          state.candidate = []
          state.deleteAccountForm = false
          state.deleteCandidateForm = false
          //manca toast e cancellazione visiva
        })
        .catch(() => {
          state.toast.error('Qualcosa è andato storto')
        })
    }
    const getfiscal_codeValidation = (e) => {
      state.modifyCandidateRegistry.fiscal_code = e
      if (state.modifyCandidateRegistry != '') {
        state.cfIsValid = codicefiscale.check(
          state.modifyCandidateRegistry.fiscal_code.toString()
        )
        this.checkCodiceFiscale(state.modifyCandidateRegistry)
      }
    }
    const getInformations = (email) => {
      state.spinner.show()
      sbapibackoffice
        .get(
          '/usercandidatesdata?email=' +
            encodeURIComponent(email.toLowerCase())
        )
        .then((response) => {
          if (response?.data === '') {
            reset()
            state.toast.error('Email non presente')
          } else {
            state.cfIsEmpty = response?.data?.fiscal_code == ''
            if (state.cfIsEmpty) state.toast.warning('I dati di fatturazione non possono essere modificati perchè l’utente non li ha ancora inseriti.')
            let obj = {
              uid: response?.data?.uid,
              name: response?.data?.first_name,
              surname: response?.data?.last_name,
              email: state.email,
              fiscal_code: response?.data?.fiscal_code.toUpperCase(),
              birthDate: response?.data?.birthDate ? dayjs(response.data.birthDate, 'YYYY-MM-DD').toDate() : null,
              birthPlace: response?.data?.birthPlace,
              birthProvince: response?.data?.birthProvince,
              address: response?.data?.address,
              city: response?.data?.city,
              cap: response?.data?.cap,
              province: response?.data?.province
            }
            console.log(obj.birthDate)
            Object.assign(state.candidateRegistry, obj)
            Object.assign(state.modifyCandidateRegistry, obj)
            state.openRegistry = true
          }
        })
        .catch((err) => {
          if (err?.response?.data?.messageCode === 'BOF-003') {
            state.toast.error('Email non presente')
          } else {
            state.toast.error(err.response.data)
          }
          reset()
        })
        .finally(()=>{
          state.spinner.hide()
        })
    }
    const getCampaignsCandidate = (email) => {
      state.spinner.show()
      api
        .get(
          '/auth/check-candidate?email=' +
            encodeURIComponent(email.toLowerCase())
        )
        .then((response) => {
          if (response.data != '') {
            response.data.forEach((c) => {
              c.application_ts = moment
                .utc(c.application_ts)
                .local()
                .format('LLLL')
              c.ts = moment.utc(c.ts).local().format('LLLL')
            })
            state.candidate = [...response.data]
          } else {
            state.candidate = []
          }
        })
        .catch((err) => {
          reset()
          state.toast.error(err.response)
        })
        .finally(()=>{
          state.spinner.show()
        })
    }
    const getCandidate = async (email) => {
      if (email) {
        reset()
        await getInformations(email)
        await getCampaignsCandidate(email)
      } else {
        reset()
      }
    }

    const reset = () => {
      state.intermediateSearch = []
      state.candidateRegistry = {}
      state.modifyCandidateRegistry = {}
      state.candidate = []
    }

    const reopen = (cid, uid) => {
      state.spinner.show()
      api
        .put('/auth/reopen-pi', { itf: [{ cid, uid }] })
        .then(() => {
          state.toast.success('Candidatura riaperta')
          getCandidate()
        })
        .catch(() => state.toast.error('Qualcosa è andato storto'))
        .then(state.spinner.hide)
    }

    const unhide = (cid, uid) => {
      state.spinner.show()
      api
        .put('/unhide-candidate', { itf: [{ cid: cid, uid: uid }] })
        .then(() => {
          state.toast.success('Candidatura ripristinata')
          getCandidate()
        })
        .catch(() => state.toast.error('Qualcosa è andato storto'))
        .then(state.spinner.hide)
    }

    const cancelApplication = (cid, uid) => {
      state.spinner.show()
      sbapibackoffice
        .delete('application', { data: { cid, uid } })
        .then(() => {
          // eliminare la campagna dalla lista
          let c = state.candidate.find((c) => c.cid === cid)
          let index = state.candidate.indexOf(c)
          if (index >= 0) {
            state.candidate.splice(index, 1)
          }
          state.toast.success('Candidatura rimossa')
          // getCandidate()
        })
        .catch(() => state.toast.error('Qualcosa è andato storto'))
        .then(state.spinner.hide)
    }
    const getCampaignList = computed(() => {
      if (state.searchCampaign == '') {
        return state.candidate
      } else {
        let filtred = []
        state.candidate.forEach((campaign) => {
          for (const property in campaign) {
            if (
              campaign[property] &&
              campaign[property].toString().toLowerCase().indexOf(state.searchCampaign.toLowerCase()) > -1
            ) {
              filtred.push(campaign)
              break
            }
          }
        })
        return filtred
      }
    })
    const manageSearchCampaign = () => {
      state.openRegistry = false
      state.openCampaigns = true
    }

    const getCandidateCVLink = (cid, uid) => {
      return `https://recruiters.cving.com/candidate/${cid}/${uid}`
    }
    const saveCandidate = () => {
      // invalid cf
      if (state.modifyCandidateRegistry.fiscal_code!= '' && !state.cfIsValid) return
      
      if (
        state.modifyCandidateRegistry.uid > 0 &&
        state.modifyCandidateRegistry.name != '' &&
        state.modifyCandidateRegistry.surname != ''
      ) {
        state.spinner.show()
        let obj = {
          first_name: state.modifyCandidateRegistry.name,
          last_name: state.modifyCandidateRegistry.surname,
          fiscal_code: state.modifyCandidateRegistry.fiscal_code,
          uid: state.modifyCandidateRegistry.uid,
          birthDate: state.modifyCandidateRegistry.birthDate ? dayjs(state.modifyCandidateRegistry.birthDate).format('YYYY-MM-DD') : '',
          birthPlace: state.modifyCandidateRegistry.birthPlace,
          birthProvince: state.modifyCandidateRegistry.birthProvince,
          cap: state.modifyCandidateRegistry.cap,
          province: state.modifyCandidateRegistry.province,
          address: state.modifyCandidateRegistry.address,
          city: state.modifyCandidateRegistry.city
        }
        sbapibackoffice
          .put('/usercandidatesdata', obj)
          .then(() => {
            state.candidateRegistry.name = obj.first_name
            state.candidateRegistry.surname = obj.last_name
            state.candidateRegistry.fiscal_code= obj.fiscal_code
            state.candidateRegistry.birthPlace = obj.birthPlace
            state.candidateRegistry.birthDate = obj.birthDate
            state.candidateRegistry.birthProvince = obj.birthProvince
            state.candidateRegistry.cap = obj.cap
            state.candidateRegistry.province = obj.province
            state.candidateRegistry.address = obj.address
            state.candidateRegistry.city = obj.city

            state.toast.success('Angrafica salvata con successo')
          })
          .catch((err) => {
            console.log('Impossibile modificare l\'anagrafica dell\'utente', err)
            state.toast.error(
              'Impossibile modificare l\'anagrafica dell\'utente'
            )
          })
          .finally(() => {
            state.spinner.hide()
          })
      }
    }
    const getTooltip = (tooltip) => {
      let n = tooltip
      if (n.length > 50) {
        n = n.slice(0, 47)
        n = n + '...'
      }
      return n
    }
    const copy = async (mytext) => {
      try {
        await navigator.clipboard.writeText(mytext)
        state.toast.success('Typeform link copiato')
       
      } catch ($e) {
        state.toast.error('Impossibile copiare il link Typeform')
      }
    }

    const checkCodiceFiscale = (user) => {
      if (!user.birthDate || !user.fiscal_code) return
      try {
        var newCfMale = new codicefiscale({
          name: user.name,
          surname:user.surname,
          gender: 'M',
          day: dayjs(user.birthDate, 'YYYY-MM-DD').format('DD'),
          month: dayjs(user.birthDate, 'YYYY-MM-DD').format('MM'),
          year: dayjs(user.birthDate, 'YYYY-MM-DD').format('YYYY'),
          birthplace: user.birthPlace, 
          birthplaceProvincia: user.birthProvince
        })
       
        var newCfFemale = new codicefiscale({
          name: user.name,
          surname:user.surname,
          gender: 'F',
          day: dayjs(user.birthDate, 'YYYY-MM-DD').format('DD'),
          month: dayjs(user.birthDate, 'YYYY-MM-DD').format('MM'),
          year: dayjs(user.birthDate, 'YYYY-MM-DD').format('YYYY'),
          birthplace: user.birthPlace, 
          birthplaceProvincia: user.birthProvince
        })
        const isSame = (user.fiscal_code === newCfFemale.code) || (user.fiscal_code === newCfMale.code)
        
        var cf = new codicefiscale(user.fiscal_code)

        const isDateValid = dayjs(cf.birthday, 'YYYY-MM-DD').format('YYYY-MM-DD') === dayjs(user.birthDate).format('YYYY-MM-DD')
        const isProvinceValid = cf.birthplace.prov.toString().toUpperCase() === user.birthProvince.toString().toUpperCase()

        return isSame && isDateValid && isProvinceValid
      } catch (error) {
        console.log(error)
        return false
      }      
    }

    const searchCandidate = () => {
      let strings = state.findCandidate.split(' ')
      strings = strings.filter(s => s.length>0)
      let search = ''
      strings.forEach(str =>{
        str = str.replace(/\+/g, '\\+')
        str = str.replace(/\./g, '\\.')
        search += '(?=.*'+ str.toLowerCase() +')'
      })
      sbapibackoffice
        .post('/searchcandidateusers', { search: search } )
        .then((response) => {
          if(response.data.length === 0){
            state.toast.error('Nessun risultato trovato')
          }
          if(response.data.length === 1){
            getCandidate(response.data[0].email)
          } 
          if(response.data.length > 1){
            state.intermediateSearch = response.data
            console.log('many results')
          }
        })
        .catch(err=>{
          state.toast.error('Qualcosa è andato storto', err)
        })
    }

    return {
      ...toRefs(state),
      searchCandidate,
      copy,
      getTooltip,
      showElimina,
      toggleCampaignOptions,
      getApiUrl,
      showDeleteAccount,
      showDeleteCandidate,
      closeDeleteAccount,
      closeDeleteCandidate,
      deleteCandidate,
      getCandidate,
      reopen,
      unhide,
      cancelApplication,
      getCampaignList,
      manageSearchCampaign,
      getCandidateCVLink,
      saveCandidate,
      getfiscal_codeValidation,
      checkCodiceFiscale,
      permissions
    }
  }
}
</script>
<style lang="scss">
.date-picker-container[disabled="true"] {
  pointer-events: none;
  label {
    color: rgb(192, 192, 192)
  }
}
.user-select-search {
  padding: 0 8px 0 0;
 width:calc(100% - 100px);
 height:40px;
 color:white;
 border:1px solid rgba(255, 255, 255, 0.55);
 margin-right: 15px;
 border-radius: 5px;
 line-height: 40px;
 .vs__selected,.vs--single.vs--open .vs__selected, .vs--single.vs--loading .vs__selected{
  color: white;
  opacity: 1!important;
 }
 .vs__dropdown-toggle{
  height: 100%;
  height: 40px;
 }
 svg {
  transform: scale(.75);
  path { fill:  rgba(255, 255, 255, 0.55);}
 }
 .vs__spinner{
  border-top: 0.9em solid rgba(	0, 132, 123,1);
    border-right: 0.9em solid rgba(0, 132, 123,.1);
    border-bottom: 0.9em solid rgba(	0, 132, 123,.1);
    border-left: 0.9em solid rgba(	0, 132, 123,.45);
 }
 ::placeholder {
  font-size: 14px;
  color:  rgba(255, 255, 255, 0.55);
}
.vs__dropdown-menu {
  background: #004177;
  color: white;
}
}
.cf-input {
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible !important;
  .error-cf {
    position: absolute;
    font-size: 12px;
    color: #ffb88b;
    opacity: 0;
    margin-left: 6px;
    transform: translateY(-3px);
    animation: all 5s 1 ease-in;
    &.visible {
      opacity: 1;
      transform: translateY(35px);
      animation: all 5s 1 ease;
    }
  }
  svg {
    position: absolute;
    right: 10px;
    top: -2px;
  }
  .tooltip {
    left: -113px !important;
    min-width: 300px;
    word-break: break-word;
    white-space: break-spaces;
  }
}
</style>

<style lang="scss" scoped>
@use "../assets/scss/checkCandidate";
@use "../assets/scss/dialog";
@use "../assets/scss/table";
@import "../assets/scss/lists.scss";
.badge-status {
  height: 22px;
  line-height: 22px;
  padding: 0 8px !important;
  padding-left: 8px !important;
  border: 0;
  border-radius: 10px;
  white-space: nowrap;
  display: inline-block;
  text-align: center;
  font-size: 13px;
  &.in-corso {
    background: #00847b;
  }
  &.archiviata {
    background: #844600;
  }
  &.scaduta {
    background: #900627;
  }
}
.campaigns-table {
  thead,
  tbody {
    display: block;
  }

  tbody.table-body {
    max-height: 50vh; /* Just for the demo          */
    overflow-y: auto !important; /* Trigger vertical scroll    */
    overflow-x: hidden; /* Hide the horizontal scroll */
  }
  .table-container {
    max-width: calc(100% - 35px);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    div:first-child {
      width: 66%;
      padding-left: 7.5px;
    }
  }
  tbody.table-body tr {
    display: table;
    width: 100%;
  }
  th:nth-of-type(1) {
    width: 60%;
  }
  th:nth-of-type(2) {
    width: 40%;
  }
}
.checkCandidate button.disabled-search {
  background-color: var(--info) !important;
  opacity: 0.75 !important;
}
.checkCandidate .bg-dark {
  background: var(--light-blue);
  border-radius: 4px;
  margin-right: 20px;
}
.checkCandidate {
  &.container-list {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
  }
  .badge {
    background-color: var(--info) !important;
    border: 1px solid var(--info) !important;
    color: white;
  }
  div.page-container {
    height: 100%;
    width: calc(100% - 100px);
    font-family: "Lato", sans-serif;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.14),
      rgba(255, 255, 255, 0.12);
    margin: 30px 50px;
    font-size: 14px;
    .scroll {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      overflow-y: auto;
      height: calc(100% - 65px);
    }
  }
  .topHeader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  table {
    .candidateThead {
      padding: 0 15px !important;
      background: #1a74a3;
      top: 0;
      position: relative !important;
      height: 60px;
      display: flex;
      align-items: center;
      th:first-of-type {
        display: flex;
        align-items: center;
        flex: 1;
        width: 100%;
      }
      th {
        padding: 0 !important;
        text-align: left;
        height: auto;
      }
      th.th-action {
        text-align: right;
        min-width: 30%;
      }
      th.th-chevron svg {
        transform: rotate(0deg);
        transition: transform 250ms ease-in-out;
      }
      &.--isOpen {
        th.th-chevron svg {
          transform: rotate(90deg);
          transition: transform 250ms ease-in-out;
        }
      }
    }

    .th-action {
      justify-content: center;
      text-align: right;
    }
    .small-button {
      font-size: 12px;
      min-width: 50px;
      margin-right: 15px;
      padding: 0 8px;
      height: 30px;
      line-height: 30px;
    }
  }
  .grid {
    display: flex;
    align-items: center;
    label {
      display: inline-block;
      width: 140px;
      text-align: right;
      margin-right: 1rem;
      color: rgba(245, 245, 245, 0.85);
    }
  }
  // overrides
  .dialog-content .dialog-text .modal-badge {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 18px;
    svg.cancel-user {
      width: 40px !important;
      height: 40px !important;
      position: absolute;
      right: 0;
      left: -67px;
      top: 0;
      bottom: 0;
      margin: auto;
      background: white;
      border-radius: 50%;
      path {
        fill: #dc3444 !important;
      }
    }
    .badge {
      background-color: white !important;
      border: 1px solid#dc3444 !important;
      width: 70px !important;
      height: 70px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      svg {
        padding-top: 5px;
        transform: scale(1.4);
        width: 160px !important;
        height: 160px !important;
      }
      svg path {
        fill: #dc3444 !important;
      }
    }
  }
  .dialog {
    .dialog-content {
      min-width: 580px;
      border-radius: 10px !important;
      .dialog-text {
        padding: 0;
        width: 100%;
        margin: 0;
      }
      .text-modal-cancel {
        width: 100%;
        font-size: 16px !important;
        margin-bottom: 9px;
        span {
          color: #dc3444;
          font-weight: 600;
        }
        + p {
          font-weight: 600;
          margin-bottom: 9px;
        }
      }
      .text-modal-cancel + p + div {
        text-align: right;
      }
      input {
        width: 100%;
        margin-bottom: 18px;
        height: 30px;
        background: #dc344420;
        border: 1px solid #dc344480;
        outline: 0;
        text-indent: 10px;
        border-radius: 4px;
        &:focus,
        &:focus-visible {
          background: #dc3444;
          border: 2px solid #dc3444;
          color: white;
          font-weight: 700;
          text-indent: 10px;
        }
      }
      button.error.pure-material-button-contained[disabled=""] {
        background: #dc3444 !important;
        opacity: 0.7;
      }
      button.error.pure-material-button-contained {
        background: #dc3444 !important;
      }
    }
    .dialog-header {
      margin: -18px 0 9px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        color: #a72632;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 18px !important;
        letter-spacing: 0em;
        text-align: left !important;
        margin-bottom: 18px;
      }
    }
  }

}

.flex-item-align {
  display: flex;
  align-items: center;
}
.alert-candidate-campaign {
  color: white;
  height: 60px;
  background: #1a74a3;
  font-size: 15px;
  padding: 1rem 1rem 1rem 0.5rem;
  margin-top: 20px;
  line-height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  .badge-alert {
    height: 35px;
    width: 35px;
    min-height: 35px;
    min-width: 35px;
    max-height: 35px;
    max-width: 35px;
    display: flex;
    background: #17a2b880;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
}

.group-dropdown {
  display: flex;
  flex-direction: column;
  gap: 7px;
  div.label{
    color: #bcde43;
  }
  select {
    width: 100%;
    background-color: #1a74a3;
    border: none;
    height: 1.8em;
    border-radius: 4px;
    color:white;
  }
}
</style>
